import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Section, PageHeroSection } from "../components/section.js";
import { Container } from "reactstrap";

export default ({ data }) => {
  const page = data.markdownRemark;
  return (
    <Layout>
      <Helmet>
        <title>Services - {page.frontmatter.title} | CleanWright</title>
      </Helmet>
      <PageHeroSection
        image={
          page.frontmatter.image &&
          page.frontmatter.image.childImageSharp.fluid.src
        }
      >
        <h1>{page.frontmatter.title}</h1>
      </PageHeroSection>
      <Section>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: page.html }} />
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              src
            }
          }
        }
      }
    }
  }
`;
